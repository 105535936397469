export default function createIntersectionObserver(fn, options = {}) {
    const observer = new IntersectionObserver(fn, {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1],
        ...options
    })


    const actions = {
        register: registerObserverOnTarget(observer, actions),
        destroy: createDestroy(observer, actions)
    }

    return actions
}

function registerObserverOnTarget(observer, actions) {
    return (target) => {
        if (target) {
            observer.observe(target)
        }
        return actions
    }
}

function createDestroy(observer, actions) {
    return (target) => {

        if (target) {
            observer.unobserve(target)
        } else {
            observer.disconnect()
        }
        return actions
    }
}