import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby";
import { gsap } from "gsap";


function currentPage(target) {
  const linkPathName = new URL(getClosetLink(target).getAttribute('href'), window.location.origin).pathname
  document.querySelector('.header--menu').classList.remove('active');
  return linkPathName !== window.location.pathname
}

const Link = ({ children, action = () => null, condition = () => true, to, ...props }) => {

  const onClick = (event) => {
    event.preventDefault();

    action(event, to)

    if (condition(event, to)) {
      document.body.classList.add('is-loading');
      if (currentPage(event.target)) {

        gsap.fromTo("main", { opacity: 1 }, { opacity: 0, duration: .85, ease: "power3.inOut", onComplete: function () { navigate(to, { state: { internal: true } }) } });

      }
    }

  }
  return (
    <GatsbyLink to={to} {...props} onClick={(event) => onClick(event)}>
      {children}
    </GatsbyLink>
  )
}
export default Link


function getClosetLink(element) {
  return element.closest('a')
}