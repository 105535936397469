import React, { useCallback, useEffect } from "react"
import { gsap } from "gsap/all"

const AnimatingLogo = ({ animating }) => {

  const getPercentage = useCallback((number) => {
    return (((number + 1) / 10) * 50)
  }, [])

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 })
    tl.from('.animation-wrapper', { y: `0%`, duration: 0, ease: 'power1.inOut' })

    for (let i = 0; i < 10; i++) {

      tl.to('.animation-wrapper', { y: `-${getPercentage(i)}%`, duration: 0.5, ease: 'power1.inOut', delay: 0.6 })
      tl.fromTo('.logo .arrow', { x: '0px' }, { x: '5px', duration: 0.1, ease: 'power1.inOut' }, '+=0.3')
      tl.fromTo('.logo .arrow', { x: '5px' }, { x: '0px', duration: 0.1, ease: 'power1.inOut' })
    }

    tl.to('.animation-wrapper', { y: `0%`, duration: 0, ease: 'power1.inOut' })
    tl.delay(0.6)
  }, [getPercentage]) 

  return (
    <div className='animating--logo flex'>
      <p className="m0 h3 logo flex"><span className="circular flex m-h2">M<span className="arrow"></span></span>
        <span className={"logo-is--animating" + (animating ? ' animating' : '')}>
          <span className="animation-wrapper">
            <span className='inner-wrapper'>
              <span>Digging deeper</span>
              <span>Advising without compromise</span>
              <span>Challenging the status quo</span>
              <span>Delivering disruptive strategies</span>
              <span>Improving project outcomes</span>
              <span>Leading the way</span>
              <span>Thinking big</span>
              <span>Digging deeper</span>
              <span>Advising without compromise</span>
              <span>Challenging the status quo</span>
            </span>
            <span className='inner-wrapper'>
              <span>Digging deeper</span>
              <span>Advising without compromise</span>
              <span>Challenging the status quo</span>
              <span>Delivering disruptive strategies</span>
              <span>Improving project outcomes</span>
              <span>Leading the way</span>
              <span>Thinking big</span>
              <span>Digging deeper</span>
              <span>Advising without compromise</span>
              <span>Challenging the status quo</span>
            </span>
          </span>
        </span>

      </p>
    </div>
  )
}
export default AnimatingLogo
