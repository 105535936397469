import React, { useEffect, useRef } from 'react'
import useForm from '../hooks/form'
import AnimatingLogo from './animatingLogo'
import Link from './link'
import Spacer from './spacer'
const Footer = ({ footer }) => {
	const footerRef = useRef()
	const { submit, submitted } = useForm()

	useEffect(() => {
		const footer = footerRef.current
		if (!footer) return
		const script = document.createElement('script')
		script.innerHTML = `
			(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
			vgo('setAccount', '28598920');
			vgo('setTrackByDefault', true);
			vgo('process');
		`
		footer.appendChild(script)

		return () => footer.removeChild(script)
	}, [])

	return (
		<div ref={footerRef} className={'colophon bg-black p40  m-pt40  ' + (footer ? footer : '')}>
			<AnimatingLogo animating={true} />
			<Spacer />
			<div className='flex m-wrap footer--links book'>
				<div className='w-50 m-100 m-mb40'>
					<a className='link inverse inline ul-link' href='https://www.instagram.com/motiv_projectmarketing/ ' target='_blank' rel='noreferrer'>
						Instagram
					</a>
					<br />
					<a className='link inverse inline ul-link' href='https://www.facebook.com/MotivPropertyGroup' target='_blank' rel='noreferrer'>
						Facebook
					</a>
					<br />
					<a className='link inverse ul-link' href='https://www.linkedin.com/company/motivpropertygroup' target='_blank' rel='noreferrer'>
						LinkedIn
					</a>
				</div>
				<div className='w-50 flex m-wrap m-100'>
					<div className='w-50 m-100 m-hide'>
						<Link className='inverse ul-link link ul-link reversed' to='/projects'>
							Projects
						</Link>
						<br />
						<Link className='inverse ul-link link' to='/about'>
							About
						</Link>
						<br />
						<Link className='inverse ul-link link' to='/services'>
							Services
						</Link>
						<br />
						<Link className='inverse ul-link link' to='/perspectiv'>
							Perspectiv
						</Link>
						<br />
						<Link className='inverse ul-link link' to='/contact'>
							Contact
						</Link>
						<br />
						<a className='link inverse ul-link' href='https://motivgroup.azurewebsites.net/login.aspx?ReturnUrl=%2f' target='_blank' rel='noreferrer'>
							Our Portal
						</a>
					</div>
					<div className='w-50 m-100'>
						<div className='max-350 m-m100'>
							<p className='m0 text-grey'>To keep up with the latest from MOTIV Group, please join our mailing list below</p>
							<form onSubmit={submit} className='m0 mt20 text-grey  w-100  pl0 pos-rel ' name='sub' method='POST' data-netlify='true'>
								<input type='hidden' name='form-name' value='sub' />
								{!submitted && (
									<>
										<div className='flex gap-20 mb10'>
											<input name='First Name' placeholder='First Name' type='text' required className='input m0 w-100 text-grey pos-rel ' />
											<input name='Surname' placeholder='Surname' type='text' required className='input m0 w-100 text-grey pos-rel ' />
										</div>
										<input name='email' placeholder='Enter your email' type='email' required className={'input m0 w-100 text-grey  footer--submitted mb10  pos-rel ' + (!submitted ? 'submitted' : '')} />
										<div className='radio-wrapper mb30 flex-col m-align-start'>
											<label htmlFor='' className='block mt20 mb20 text-grey'>
												How would you best describe yourself?
											</label>
											<div id='description' className='flex flex-col gap-10px'>
												<div className='flex gap-10px align-center text-grey'>
													<div className='radio-input-wrapper flex '>
														<input id='property-industry-professional-footer' className='light' type='radio' name='description' value='Property Industry Professional' />
													</div>
													<label htmlFor='property-industry-professional-footer' className='radio-label'>
														Property Industry Professional
													</label>
												</div>
												<div className='flex gap-10px align-center text-grey'>
													<div className='radio-input-wrapper flex'>
														<input id='purchaser-footer' className='light' type='radio' name='description' value='Purchaser' />
													</div>
													<label htmlFor='purchaser-footer' className='radio-label'>
														Purchaser
													</label>
												</div>
											</div>
										</div>
									</>
								)}
								<div className={'mb20 m0  submitted-state footer--form mb10  pos-rel w-100 top-0 captialcase ' + (!submitted ? 'submitted' : '')}>Thanks for Submitting</div>
								<button className='form-button medium circ-std p0 inline'>Subscribe</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Spacer />
			<div className='flex m-wrap book small align-bottom'>
				<div className='w-50 m-100 flex m-wrap'>
					<div className='w-50 m-100 m-mb40'>
						Brisbane Office <br />
						4/1 Chermside Street
						<br />
						Teneriffe, QLD 4005
						<br />
					</div>
					<div className='w-50 m-100 m-mb40'>
						Gold Coast Office <br />
						Suite 3, 33 James St,
						<br />
						Burleigh Heads 4220
					</div>
				</div>
				<div className='w-50 m-100 flex align-bottom m-mb10'>
					<div className='w-50 m-100'>
						<div className='footer-info flex flex-col gap-10 align-start'>
							<p className='m0'>© {new Date().getFullYear()} MOTIV Group</p>
							<Link className='inverse ul-link link' to='/disclaimer'>
								Disclaimer
							</Link>
							<Link className='inverse ul-link link' to='/privacy-policy'>
								Privacy Policy
							</Link>
						</div>
					</div>
					<div className='w-50 m-100 m-mt40'>
						<div className='mla m-text-right'>
							Website by{' '}
							<a className='link inverse ul-link uppercase' href='https://groundcrew.com.au' target='_blank' rel='noreferrer'>
								Groundcrew
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Footer
