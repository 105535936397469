/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import Preloader from "../components/preloader" 
import ConditionallyRender from "../components/utils/conditionally-render"  
import PageTransition from "../components/pageTransition"
import "../scss/site.scss"  

const Layout = ({ footer, children, header,home = false,className }) => {
  return (
    <main>
    <Header className={className} position={header} />
    <PageTransition />
    <ConditionallyRender when={home}>
      <Preloader /> 
    </ConditionallyRender>
    <div className="page-will--translate">
      {children}
      <Footer footer={footer} />
    </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
