import { useState } from "react"
export default function useForm() {

    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(``)
    const [unfilledRequired,setUnfilledRequired] = useState([])

    async function submit(e) {
        e.preventDefault()  
        const form = getForm(e)
        const required = findRequired(form)
        const formData = new FormData(form)
        const unfilledRequired = validateRequired(formData, required)

        setUnfilledRequired(unfilledRequired)
        if (unfilledRequired.size === 0) {
            try {
                await fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams(formData).toString(),
                })
                setError(``)
                setSubmitted(true)
            

            } catch (e) {
                setSubmitted(false)
                console.error(e)
                setError(`An error occured`)
            }

        } else {
            setSubmitted(false)
        
            setError(`unfilled required fields`)
        }
    }
    return { submit, error, submitted,unfilledRequired }
}


function findRequired(form) {
    return Array.from(form.querySelectorAll("input")).filter((element) => {
        return getRequiredValue(element)
    }).map((element) => {
        return {
            name: getNameValue(element),
            required: getRequiredValue(element)
        }
    })
}

function getRequiredValue(input) {
    const requiredValue = input.getAttribute('required')
    if (requiredValue === '' || Boolean(requiredValue)) {
        return true
    }
    return false
}

function getNameValue(input) {
    return input.getAttribute('name')
}


function getForm(event) {
    return event.target.closest('form')
}

function validateRequired(formData, requiredValues) {

    const consolidatedRequired = requiredValues.reduce((consolidated,input) => {
        if (formData.get(input.name)) {
            return consolidated
        } else {
            return consolidated.add(input.name)
        }
    },new Set([]))

    const handler = {
        get : function (target,name) {
            if(name === 'size'){
                return target[name]
            }
            return target.has(name)
        },
        set: () => {}
    }
    const proxy = new Proxy(consolidatedRequired,handler)
    return proxy

}