import { useEffect, useState } from 'react'

export const useMobile = () => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		function checkIfOnMobile() {
			if (window.innerWidth > 950) return setIsMobile(false)
			setIsMobile(true)
		}

		checkIfOnMobile()

		window.addEventListener('resize', checkIfOnMobile)
		return () => window.removeEventListener('resize', checkIfOnMobile)
	}, [])

	return isMobile
}
