import React, { useEffect } from 'react'
import { gsap } from "gsap";

const Preloader = () => {
  useEffect(() => {
    window.scroll(0,0); 
    document.body.classList.add('locked');
    var tl = gsap.timeline({ paused: true});
    tl.to('.intro--animation .first', { transform:'translate(100%,-100%)', ease: "power2.inOut", duration:1.1, delay:0.5 });
    tl.to('.intro--animation .second', { transform:'translate(0%,0%)', ease: "power2.inOut", duration:1.1 }, '-=1');
    tl.to('.intro--animation .second', { transform:'translate(100%,-100%)', ease: "power2.inOut", duration:1});
    tl.to('.intro--animation', {duration:0, onComplete:function(){document.body.classList.remove('locked');document.querySelector('.scrolling--logo').classList.add('loaded');}}, '-=.5');
    tl.to('.preloader', { opacity:0, display:'none', ease: "power2.inOut", duration:1, onComplete:function(){setTimeout(function(){ document.querySelector('.home--logo .logo-is--animating')?.classList.add('animating') }, 500);}}, '-=.5');
    tl.play(); 
  });
  return (
  <div className='preloader'>
    <div className="intro--animation">
      <span className='first'></span>
      <span className='second'></span>
    </div>
  </div> 
  )
} 
export default Preloader
