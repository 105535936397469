import React, { useEffect } from "react"
import { gsap } from "gsap";

const PageTransition = ({ internal }) => {

  useEffect(() => {
    document.body.style.backgroundColor = '';
    setTimeout(function () { document.body.classList.remove('is-loading'); document.body.classList.remove('newsletter-visible'); document.querySelector('main').style.transform = 'none'; }, 1600);

    gsap.to('main', { opacity: 1, duration: 1, ease: "power3.inOut" });

  }, [internal]);

  return (
    <></>
  )
}
export default PageTransition