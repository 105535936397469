import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useMobile } from '../hooks/mobile'
import createIntersectionObserver from '../utils/registerIntersectionObserver'
import Link from './link'
import Logo from './logo'

const Header = ({ siteTitle, position, className }) => {
	const onHover = () => {
		document.querySelector('.header--menu').classList.toggle('active')
	}
	function getBody() {
		return document.querySelector('body')
	}
	function updateHeight(el, set) {
		if (set) {
			el.style.height = '100vh'
			el.style.overflow = 'hidden'
			return
		}
		el.style.height = 'auto'
		el.style.overflow = 'unset'
		return
	}
	const onClick = () => {
		var button = document.querySelector('.menu--button')
		var overlay = document.querySelector('.overlay--menu')
		if (window.innerWidth < 1000) {
			if (button.classList.contains('active')) {
				close(overlay, button)
			} else {
				open(overlay, button)
			}
		}
	}
	function open(overlay, button) {
		updateHeight(getBody(), true)
		document.querySelector('.masthead').classList.add('is-open')
		button.innerHTML = 'Close'
		overlay.classList.add('active')
		button.classList.add('active')
	}
	function close(overlay, button) {
		updateHeight(getBody(), false)
		document.querySelector('.masthead').classList.remove('is-open')
		button.innerHTML = 'Menu'
		overlay.classList.remove('active')
		button.classList.remove('active')
	}
	const mouseOut = () => {}
	function checkLink(_, to) {
		if (standardiseUrlPath(window.location) === standardisePath(to)) {
			const button = document.querySelector('.menu--button')
			const overlay = document.querySelector('.overlay--menu')
			close(overlay, button)
			return false
		}
		updateHeight(getBody(), false)
		return true
	}
	function standardiseUrlPath(location) {
		return location.pathname.replaceAll('/', '')
	}
	function standardisePath(to) {
		return to.replaceAll('/', '')
	}

	useEffect(() => {
		const observer = createIntersectionObserver(eventDispatcher)
		observer.register(document.querySelector('.parallax--banner'))
		return () => observer.destroy()
	}, [])

	const eventDispatcher = useCallback(entries => {
		const parallaxBanner = entries[0]

		switch (isInView(parallaxBanner)) {
			case 'inView':
				return toggleBackground('.masthead', false, getBackgroundClass())
			case 'notInView':
				return toggleBackground('.masthead', true, getBackgroundClass())
		}
	}, [])

	const getBackgroundClass = useCallback(() => {
		if (document.querySelector('.bg--light')) {
			return 'bg--light'
		}
		return 'with-background'
	}, [])

	const toggleBackground = useCallback((selector, flag, backgroundClass) => {
		const el = document.querySelector(selector)

		if (flag) {
			return el.classList.add(backgroundClass)
		}
		return el.classList.remove(backgroundClass)
	}, [])

	const isInView = useCallback(entry => {
		return entry.isIntersecting ? 'inView' : 'notInView'
	}, [])

	useEffect(() => {
		return () => document.querySelector('.masthead').classList.remove('bg--light')
	}, [])

	const [isPerspectivPage, setIsPerspectivPage] = useState(false)
	const [isScrolledPastTitle, setIsScrolledPastTitle] = useState(false)

	useEffect(() => (window.location.pathname === '/perspectiv' ? setIsPerspectivPage(true) : null), [setIsPerspectivPage])

	useEffect(() => {
		if (!isPerspectivPage) return

		function checkIfScrolledPastTitle() {
			window.scrollY >= 200 ? setIsScrolledPastTitle(true) : setIsScrolledPastTitle(false)
		}

		checkIfScrolledPastTitle()

		window.addEventListener('scroll', checkIfScrolledPastTitle)
		return () => window.removeEventListener('scroll', checkIfScrolledPastTitle)
	}, [isPerspectivPage, isScrolledPastTitle, setIsScrolledPastTitle])

	const isMobile = useMobile()

	const menu = (
		<>
			<Link condition={checkLink} className='inverse ul-link op-link link i-ml10' to='/projects'>
				Projects
			</Link>
			<Link condition={checkLink} className='inverse ul-link op-link link ml20 i-ml10' to='/about'>
				About
			</Link>
			<Link condition={checkLink} className='inverse ul-link op-link link ml20 i-ml10' to='/services'>
				Services
			</Link>
			<Link condition={checkLink} className='inverse ul-link op-link link ml20 i-ml10' to='/perspectiv'>
				Perspectiv
			</Link>
			<Link condition={checkLink} className='inverse ul-link op-link link ml20 i-ml10' to='/contact'>
				Contact
			</Link>
			<a className='link inverse ul-link op-link m-show' href='https://motivgroup.azurewebsites.net/login.aspx?ReturnUrl=%2f' target='_blank' rel='noreferrer'>
				Our Portal
			</a>
		</>
	)

	return (
		<>
			<header className={'masthead ' + className + ' ' + (position ? position : '')} onMouseLeave={mouseOut} role='presentation'>
				<div className='header--grid p20 p4020'>
					<div className='menu flex'>
						<p className='m0 m-show menu--button active book' role='presentation' onClick={onClick} onMouseOver={onHover} onFocus={onHover}>
							Menu
						</p>
						<div className={`header--menu ${isMobile ? '' : 'active'} i-hide book`}>{menu}</div>
					</div>
					<Link className={`logo link text-center overflow ${isPerspectivPage ? 'perspectiv-logo-wrapper' : ''}`} to='/'>
						<Logo />
						{isPerspectivPage && <p className={`h5 perspectiv-logo ${isScrolledPastTitle ? 'active' : ''} m0 text-center`}>Perspectiv</p>}
					</Link>
					<div className='text-right m-hide'>
						<Link className='link ul-link book ' to='/contact'>
							Enquire
						</Link>
					</div>
				</div>
			</header>
			<div className='overlay--menu'>
				<div className='inner flex flex-wrap'>
					<div className='p20 pt0 mt40 w-100 i-p40 m-p20'>{menu}</div>
					<div className='mta p20 op-50 bottom-menu'>
						<a className='link small ul-link' href='https://www.instagram.com/_motiv_group' target='_blank' rel='noreferrer'>
							Instagram
						</a>
						<br />
						<a className='link small ul-link' href='https://www.facebook.com/MotivPropertyGroup' target='_blank' rel='noreferrer'>
							Facebook
						</a>
						<br />
						<a className='link small ul-link' href='https://www.linkedin.com/company/motivpropertygroup' target='_blank' rel='noreferrer'>
							LinkedIn
						</a>
					</div>
				</div>
			</div>
		</>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
